import React, { useContext } from 'react';

import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import AuthenticationSection from '../authentication/AuthenticationSection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../authentication/AuthContext';
import Swal from 'sweetalert2';


const AccountMain = () => {
  const { t, i18n } = useTranslation(); // Destructure i18n here
  const { handleDeleteAccount } = useContext(AuthContext);
  

  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };

  const titles = {
    en: "Regaleyes | My Account",
    ar: "ريجل ايز | الملف الشخصي"
  };

  const descriptions = {
    en: "Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort.",
    ar: "اكتشف Regaleyes، وجهتك الأولى للحصول على منتجات بصرية عالية الجودة. استكشف مجموعتنا الواسعة من العدسات والنظارات والإكسسوارات المصممة للأناقة والراحة."
  };

  // Get the current language
  const currentLang = i18n.language; // or use i18n.languages[0] for the first language
  const currentKeywords = keywords[currentLang];
  const currentTitle = titles[currentLang];
  const currentDescription = descriptions[currentLang];

  const confirmDeleteAccount = async () => {
    const result = await Swal.fire({
      title: t('Are you sure?'),
      text: t("You won't be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t('Yes, delete it!')
    });

    if (result.isConfirmed) {
      // Call the handleDeleteAccount function from context
      handleDeleteAccount();
      Swal.fire({
        title: t('Deleted!'),
        text: t('Your account has been deleted.'),
        icon: "success"
      });
    }
  };


  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
        <meta name="description" content={currentDescription} />
        <meta name="keywords" content={currentKeywords} />
      </Helmet>

      <BreadcrumbSection title={t('My Account')} current={t('My Account')} />
      <AuthenticationSection />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button 
          onClick={confirmDeleteAccount} // Use confirmDeleteAccount for the click event
          style={{
            backgroundColor: 'black',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px'
          }}
        >
          {t('Delete Account')}
        </button>
      </div>


    </>
  );
}

export default AccountMain;