import React from 'react';
import { useTranslation } from 'react-i18next';

const PageDetailSectionPrivacy = () => {
    const { i18n } = useTranslation();

    // Hardcoded privacy policy text with JSX elements
    const privacyPolicyText = {
        en: (
            <>
                <h1>Privacy Policy</h1>
                <br />
                <h2>Terms of Use</h2>
                <p>
                    By using the Regal Eyes website, you agree to collect and use your data and its impact on this policy, so we ask you to read this policy carefully.
                </p>
                <h2>Protection of Your Information</h2>
                <p>
                    Regal Eyes is committed to providing the necessary protection to save the information you provide when you register on the site, and Regal Eyes is also committed not to sell or rent your personal information to any third party without your consent except as stated in this document, so we thank you for your trust and we would like you to use our site with complete safety and comfort.
                </p>
                <h2>Third Party</h2>
                <p>
                    Regal Eyes employs other companies to perform the functions necessary to provide the services we provide to you through our site, for example: delivery of orders is through shipping service providers, and if you have access to your personal information necessary to perform the functions, not to use it for other purposes.
                </p>
                <h2>Information Collected</h2>
                <p>
                    Regal Eyes collects the information provided by you during our registration on the site, including the following:
                </p>
                <ul>
                    <li>First, middle and last name.</li>
                    <li>Contact number.</li>
                    <li>Email.</li>
                    <li>Address.</li>
                </ul>
                <p>
                    If you wish to delete your account or unsubscribe from the newsletter, you can do so at any time you wish to do so.
                </p>
                <h2>Payment Data</h2>
                <p>
                    Regal Eyes does not provide a direct payment method through its website and provides the following payment methods: (For example)
                </p>
                <ul>
                    <li>Payment by credit cards.</li>
                    <li>Payment at the branch is available when the customer wishes to receive his order.</li>
                    <li>Payment through Apple Pay.</li>
                </ul>
                <p>
                    All the payment methods we provide are safe, God willing, so there is nothing to worry about for its users, otherwise you will be forced to cancel the registration of their bank data within the site to complete the purchase process.
                </p>
                <h2>Methods of Using Your Information</h2>
                <p>
                    When collecting your information, Regal Eyes may use it in one of the following areas:
                </p>
                <ul>
                    <li>Complete the transaction with you.</li>
                    <li>Communicate with you when necessary.</li>
                    <li>Provide you with new offers and services.</li>
                    <li>Improve the services provided to you.</li>
                </ul>
                <h2>Disclosure of Your Information</h2>
                <p>
                    Regal Eyes is committed not to use your information for any purpose other than the above, and it is also considered after selling or renting your information to any other party except when:
                </p>
                <ul>
                    <li>Obtaining your permission.</li>
                    <li>The necessity of achieving procedures related to preventing illegal and unlawful activities, such as fraud or posing a threat to the safety and security of any person.</li>
                    <li>Dealing with the terms of use of the Regal Eyes website.</li>
                    <li>Defending Regal Eyes in legal claims.</li>
                    <li>Special circumstances such as procedures for court orders or legal authorities.</li>
                </ul>
                <h2>Security</h2>
                <p>
                    Regal Eyes uses the best security methods available to protect your personal information in our website database, we take all electronic, physical and administrative procedures to protect information, however, we cannot guarantee the protection of your information completely, so by using the Regal Eyes website, we pledge not to bear any responsibility for disclosing your private information due to unauthorized actions by any third party or any errors in transmission.
                </p>
                <h2>Registration and Access to Your Data with Us</h2>
                <p>
                    Regal Eyes does not require your registration and seeks to obtain the contents available on the site from services and products, so registration with us is your choice and the request is when you wish to purchase with us electronically.
                </p>
                <p>
                    To protect your information, when you try to access your account on the site, verify your identity before granting you access to your profile with us. We ask for the username and password you created to create your access to your profile. Update your profile and follow up on your current and previous requests.
                </p>
                <p>
                    You can delete your account at any time if you do not have the required period. Regal Eyes has the right to update and amend the privacy policy at any time and it is effective to update it on the site and is applicable to all who have registered on the site since its inception until the present time.
                </p>
                <p>
                    By agreeing to this policy, you agree to all of the above.
                </p>
            </>
        ),
        ar: (
            <>
                <h1>سياسة الخصوصية</h1>
                <br />

                <h2>شروط الاستخدام</h2>
                <p>
                    باستخدام موقع ريجل آيز، فإنك توافق على جمع واستخدام بياناتك وتأثيرها على هذه السياسة، لذا نطلب منك قراءة هذه السياسة بعناية.
                </p>
                <h2>حماية معلوماتك</h2>
                <p>
                    تلتزم ريجل آيز بتقديم الحماية اللازمة لحفظ المعلومات التي تقدمها عند تسجيلك على الموقع، كما تلتزم ريجل آيز بعدم بيع أو تأجير معلوماتك الشخصية لأي طرف ثالث دون إذنك باستثناء ما هو مذكور في هذا المستند، لذا نشكرك على ثقتك ونود أن تستخدم موقعنا بأمان وراحة تامة.
                </p>
                <h2>الطرف الثالث</h2>
                <p>
                    تستخدم ريجل آيز شركات أخرى لأداء الوظائف اللازمة لتقديم الخدمات التي نقدمها لك من خلال موقعنا، على سبيل المثال: تسليم الطلبات يتم من خلال مقدمي خدمات الشحن، وإذا كان لديك وصول إلى معلوماتك الشخصية اللازمة لأداء الوظائف، فلا تستخدمها لأغراض أخرى.
                </p>
                <h2>المعلومات التي تم جمعها</h2>
                <p>
                    تجمع ريجل آيز المعلومات المقدمة من قبلك أثناء تسجيلك على الموقع، بما في ذلك ما يلي:
                </p>
                <ul>
                    <li>الاسم الأول، الاسم الأوسط، واسم العائلة.</li>
                    <li>رقم الاتصال.</li>
                    <li>البريد الإلكتروني.</li>
                    <li>العنوان.</li>
                </ul>
                <p>
                    إذا كنت ترغب في حذف حسابك أو إلغاء الاشتراك من النشرة الإخبارية، يمكنك القيام بذلك في أي وقت ترغب فيه.
                </p>
                <h2>بيانات الدفع</h2>
                <p>
                    لا تقدم ريجل آيز طريقة دفع مباشرة من خلال موقعها وتوفر طرق الدفع التالية: (على سبيل المثال)
                </p>
                <ul>
                    <li>الدفع بواسطة بطاقات الائتمان.</li>
                    <li>الدفع في الفرع متاح عندما يرغب العميل في استلام طلبه.</li>
                    <li>الدفع عبر Apple Pay.</li>
                </ul>
                <p>
                    جميع طرق الدفع التي نقدمها آمنة، إن شاء الله، لذا لا يوجد ما يدعو للقلق لمستخدميها، خلاف ذلك ستضطر لإلغاء تسجيل بياناتهم المصرفية ضمن الموقع لاستكمال عملية الشراء.
                </p>
                <h2>طرق استخدام معلوماتك</h2>
                <p>
                    عند جمع معلوماتك، قد تستخدم ريجل آيزها في أحد المجالات التالية:
                </p>
                <ul>
                    <li>إتمام المعاملة معك.</li>
                    <li>التواصل معك عند الضرورة.</li>
                    <li>تزويدك بعروض وخدمات جديدة.</li>
                    <li>تحسين الخدمات المقدمة لك.</li>
                </ul>
                <h2>إفشاء معلوماتك</h2>
                <p>
                    تلتزم ريجل آيز بعدم استخدام معلوماتك لأي غرض آخر بخلاف ما سبق، كما تعتبر بعد بيع أو تأجير معلوماتك لأي طرف آخر باستثناء عندما:
                </p>
                <ul>
                    <li>الحصول على إذنك.</li>
                    <li>ضرورة تحقيق الإجراءات المتعلقة بمنع الأنشطة غير القانونية مثل الاحتيال أو تهديد السلامة والأمان لأي شخص.</li>
                    <li>التعامل مع شروط استخدام موقع نظارات ريجل آيز.</li>
                    <li>الدفاع عن نظارات ريجل آيز في دعاوى قانونية.</li>
                    <li>ظروف خاصة مثل الإجراءات المتعلقة بأوامر محكمة أو السلطات القانونية.</li>
                </ul>
                <h2>الأمان</h2>
                <p>
                    تستخدم نظارات ريجل آيز أفضل طرق الأمان المتاحة لحماية معلوماتك الشخصية في قاعدة بيانات موقعنا، نتخذ جميع الإجراءات الإلكترونية والفيزيائية والإدارية لحماية المعلومات، ومع ذلك، لا يمكننا ضمان حماية معلوماتك بشكل كامل، لذا باستخدامك لموقع نظارات ريجل آيز، نعدك بعدم تحمل أي مسؤولية عن إفشاء معلوماتك الخاصة بسبب أفعال غير مصرح بها من أي طرف ثالث أو أي أخطاء في النقل.
                </p>
                <h2>التسجيل والوصول إلى بياناتك معنا</h2>
                <p>
                    لا تتطلب نظارات ريجل آيز تسجيلك وتسعى للحصول على المحتويات المتاحة على الموقع من خدمات ومنتجات، لذا فإن التسجيل معنا هو خيارك والطلب هو عندما ترغب في الشراء معنا إلكترونيًا.
                </p>
                <p>
                    لحماية معلوماتك، عند محاولة الوصول إلى حسابك على الموقع، تحقق من هويتك قبل منحك الوصول إلى ملفك الشخصي معنا. نطلب اسم المستخدم وكلمة المرور التي أنشأتها لإنشاء وصولك إلى ملفك الشخصي. تحديث ملفك الشخصي ومتابعة طلباتك الحالية والسابقة.
                </p>
                <p>
                    يمكنك حذف حسابك في أي وقت إذا لم يكن لديك الفترة المطلوبة. تحتفظ ريجل آيز بحق تحديث وتعديل سياسة الخصوصية في أي وقت وتكون سارية لتحديثها على الموقع وتطبق على جميع من سجلوا في الموقع منذ بدايته حتى الوقت الحاضر.
                </p>
                <p>
                    بموافقتك على هذه السياسة، توافق على كل ما سبق.
                </p>
            </>
        )
    };

    return (
        <div className="container">
            <main className="blog-page-content">
                <div className="pages-container fz-blog-details-container">
                    <div className="blogs">
                        <div className="fz-single-blog fz-inner-page-blog fz-blog-details">
                        <div className="fz-single-blog__img fz-blog-details__img">
                            <img src={"../../public/assets/images/privacy-policy.webp"} alt="privacy policy" />

                        </div>
                            <div className="fz-single-blog__txt fz-blog-details__txt">
                                <div className="fz-single-blog__desc fz-blog-details__descr">
                                    {privacyPolicyText[i18n.language]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default PageDetailSectionPrivacy;