import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"; // No need for useParams here
const apiUrl = import.meta.env.VITE_API_URL;

const FrameBrandFilterCategory = ({ categorySlug,subcategorySlug }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    // Fetch all brands
    axios.get(`${apiUrl}/api/brands`)
      .then(brandResponse => {
        const allBrands = brandResponse.data.reduce((acc, brand) => {
          acc[brand.id] = {
            id: brand.id,
            title: i18n.language === 'ar' ? brand.name_ar : brand.name_en,
            image: brand.image,
          };
          return acc;
        }, {});

        setBrands(allBrands);
        setFilteredBrands(Object.values(allBrands));
      })
      .catch(error => {
        console.error("Error fetching brands:", error);
      });
  }, [i18n.language]);

  const handleBrandClick = (brandId) => {
    if (categorySlug) {
      navigate(`/products/category/${categorySlug}/brand/${brandId}`);
    } else {
      // Fallback if no category slug is provided
      navigate(`/shop/search?brand_id=${brandId}`);
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Brands')}</h3>
      <ul className="frame-shape">
        {(showAll ? filteredBrands : filteredBrands.slice(0, 12)).map((brand) => (
          <li className="shape-item" key={brand.id}>
            <a href="#" onClick={(e) => { e.preventDefault(); handleBrandClick(brand.id); }}>
              <div className="fz-shape-img">
                <img src={`${apiUrl}/uploads/${brand.image || ''}`} alt={brand.title} />
              </div>
              <div className="fz-shape-txt">
                <span>{brand.title}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <button className="btn btn-link" onClick={toggleShowAll}>
        {showAll ? t('Show Less') : t('Show All')}
      </button>
    </div>
  );
};

export default FrameBrandFilterCategory;