import React, { useState, useEffect } from "react";
import BreadcrumbSection2 from '../breadcrumb/BreadcrumbSection2';
import ShopAreaSection3 from '../shop/ShopAreaSection3';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const apiUrl = import.meta.env.VITE_API_URL;

const ShopMain = () => {
  // console.log('ShopMain rendered', { slug, brandId }); // Debug log
  const { t, i18n } = useTranslation();
  const { slug, brandId, categorySlug } = useParams(); // Get both slug and brandId from URL
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubcategory, setSelectedSubcategory] = useState({});

  // SEO Start
  const [metaTitle, setMetaTitle] = useState('');
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const titles = {
    en: "Regaleyes | ",
    ar: "ريجل ايز | "
  };
  const descriptions = {
    en: "Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort.",
    ar: "اكتشف Regaleyes، وجهتك الأولى للحصول على منتجات بصرية عالية الجودة. استكشف مجموعتنا الواسعة من العدسات والنظارات والإكسسوارات المصممة للأناقة والراحة."
  };

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
  const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
  const currentDescription = i18n.language === 'ar' ? descriptions.ar : descriptions.en;
  // SEO End

  useEffect(() => {
    // Fetch categories
    axios.get(`${apiUrl}/api/categories`)
      .then(response => {
        setCategories(response.data);
        const category = response.data.find(category => category.slug === slug);
        if (category) {
          setSelectedCategory(category);
          setMetaTitle(i18n.language === 'ar' ? category.name_ar : category.name_en);
        }
      })
      .catch(error => {
        console.error(error);
      });

    // Fetch subcategories
    axios.get(`${apiUrl}/api/subcategories`)
      .then(response => {
        setSubcategories(response.data);
        const subcategory = response.data.find(subcategory => subcategory.slug === slug);
        if (subcategory) {
          setSelectedSubcategory(subcategory);
          setMetaTitle(i18n.language === 'ar' ? subcategory.name_ar : subcategory.name_en);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [slug, apiUrl, i18n.language]);

  return (
    <>
      <Helmet>
        <title>{currentTitles} {metaTitle}</title>
        <meta name="description" content={currentDescription} />
        <meta name="keywords" content={currentKeywords} />
      </Helmet>
      <BreadcrumbSection2
        title={selectedCategory.name_en || selectedSubcategory.name_en || ''}
        currentPage={t("Products")}
      />
      <ShopAreaSection3 brandId={brandId} categorySlug={categorySlug} /> {/* Pass brandId to ShopAreaSection3 */}
    </>
  );
};

export default ShopMain;