import React, { useContext, useState, useEffect } from 'react';
import { FarzaaContext } from '../../context/FarzaaContext';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../context/StoreContext';
import { CurrencyContext } from '../../context/CurrencyContext';
import { AuthContext } from '../authentication/AuthContext';
import axios from 'axios';
import { Riple } from "react-loading-indicators";

const apiUrl = import.meta.env.VITE_API_URL;

const ProductContainer2 = ({ shapeId, genderId, brandId, categoryId }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const { token } = useContext(AuthContext);
  const { selectedCurrency, currencyImages,value } = useContext(CurrencyContext);
  const navigate = useNavigate();
  const [products, setCategoryProducts] = useState([]);
  const [pagination, setPagination] = useState({});

  const { isListView } = useContext(FarzaaContext);
  const { addToWishlist, addToCart } = useContext(StoreContext);

  useEffect(() => {
    fetchProducts();
  }, [slug, brandId, shapeId, genderId, categoryId]);

  const handleAddToCart = (product) => {
    if (!token) {
      navigate('/login');
    } else {
      addToCart(product);
    }
  };
  const fetchProducts = async (pageNumber = 1) => {
    try {
      let url;
  
      // Determine if the slug is a subcategory
      const isSubcategory = checkIfSubcategory(slug); // Implement this function based on your logic
  
      // Case 1: Subcategory + Brand
      if (slug && brandId && isSubcategory) {
        url = `${apiUrl}/api/products/subcategory/${slug}/brand/${brandId}`;
        console.log('Fetching products for subcategory + brand:', url);
      } 
      // Case 2: Category + Brand
      else if (slug && brandId) {
        url = `${apiUrl}/api/products-category-${slug}-brand-${brandId}`;
        console.log('Fetching products for category + brand:', url);
      } 
      // Case 3: Category or Subcategory via pathname/slug
      else {
        url = `${apiUrl}/api${pathname}`; // Adjust this as needed
        console.log('Fetching products for category/subcategory:', url);
      }
  
      // Add query parameters for filters
      const params = new URLSearchParams();
      if (shapeId) params.append('shape_id', shapeId);
      if (genderId) params.append('gender_id', genderId);
      if (!brandId && slug) params.append('slug', slug); // Add slug for category/subcategory
      else if (!slug && brandId) params.append('brand_id', brandId); // Add brandId for fallback
  
      // Append the filters to the URL
      if (params.toString()) {
        url += `?${params.toString()}`; // Use ? instead of & for the first query parameter
      }
      console.log('Final URL:', url);
  
      // Fetch data
      const response = await axios.get(url);
      console.log('Raw API Response:', response.data);
  
      // Normalize the response into products and pagination
      let productsData, paginationData;
  
      // Handle the response structure
      if (Array.isArray(response.data)) {
        productsData = response.data; // Direct array
        paginationData = {};
      } else if (response.data.products) {
        productsData = response.data.products; // e.g., { products: [] }
        paginationData = {};
      } else if (response.data.data) {
        productsData = response.data.data; // e.g., { data: [] }
        paginationData = {
          current_page: response.data.current_page || 1,
          last_page: response.data.last_page || 1,
          links: response.data.links || [],
        };
      } else {
        productsData = []; // Fallback if structure is unknown
        paginationData = {};
        console.warn('Unexpected response structure:', response.data);
      }
  
      // Ensure productsData is an array
      if (!Array.isArray(productsData)) {
        console.error('productsData is not an array:', productsData);
        productsData = [];
      }
  
      console.log('Normalized Products Data:', productsData);
      console.log('Pagination Data:', paginationData);
  
      setCategoryProducts(productsData);
      console.log('Updated Products State:', productsData);

      setPagination(paginationData);
    } catch (error) {
      console.error("Error fetching products:", error.response?.data || error.message);
      setCategoryProducts([]);
      setPagination({});
    } finally {
      setLoading(false); // End loading
    }
  };
  
  // Example function to check if the slug corresponds to a subcategory
  const checkIfSubcategory = (slug) => {
    // Implement your logic here to determine if the slug is a subcategory
    // For example, you could check against a list of known subcategory slugs
    const subcategorySlugs = [
      'men-sunglasses',
      'women-sunglasses',
      'uni-sex-sunglasses',
      'men-eyeglasses',
      'women-eyeglasses',
      'uni-sex-eyeglasses',
      'clear-lenses',
      'colored-lenses',
      'women-accessories',
      'unisex-accessories'
      ]; // Example based on your slug
    return subcategorySlugs.includes(slug);
  };
  // Placeholder function to determine if slug is a subcategory
   if (loading) {
    return (
      <div>
        <Riple color="#5c5555" size="large" text="Loading" textColor="#000000" />
        <p>Loading products...</p>
      </div>
    );
  }

  console.log('Products to render:', products);

  return (
    <div className={`fz-inner-products-container ${isListView ? 'list-view-on' : ''}`}>
      <div className="row justify-content-center">
        {products.length === 0 ? (
          <div className='no-product-area'>
            <h3 className='no-product-text'>{t('No Products Available')}</h3>
            <p className='no-product-desc'>{t("We're sorry. We cannot find any matches for your search term.")}</p>
          </div>
        ) : (
          products.map((product) => (
            <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-2" key={product.id}>
              <div className="fz-5-single-product">
                <div className="fz-5-single-product-img">
                  <Link className="fz-5-single-product-img" to={`/product/${product.slug_en}`}>
                    <img src={`${apiUrl}/uploads/${product.image}`} alt={product.title_en} />
                  </Link>
                  <div className="fz-5-single-product-actions">
                    <a role="button" className="fz-add-to-wishlist" onClick={() => addToWishlist(product)}>
                      <i className="fa-regular fa-heart"></i>
                    </a>
                    <a role="button" className="fz-add-to-cart-btn" onClick={() => handleAddToCart(product)}>
                      <i className="fa-regular fa-cart-shopping"></i>
                    </a>
                  </div>
                </div>
                <div className="fz-5-single-product-txt">
                  <h3 className="fz-5-single-product-title">
                    <Link to={`/product/${product.slug_en}`}>
                      {i18n.language === 'ar' ? product.title_ar : product.title_en}
                    </Link>
                  </h3>
                  <Link className="fz-5-single-product-price" to={`/product/${product.slug_en}`}>
  <p className="fz-5-single-product-price">
    {t(selectedCurrency)} 
    {selectedCurrency === "SAR" && (
      <img 
        src={currencyImages[selectedCurrency]} 
        alt={selectedCurrency} 
        style={{ width: '24px', height: 'auto', marginLeft: '10px' }} 
      />
    )}
    {(product.selling_price * value).toFixed(2)}
  </p>
</Link>                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="p-2">
        {pagination.links && pagination.links.length > 0 && (
          <nav aria-label="Pagination">
            <ul className="pagination justify-content-center flex-wrap">
              {pagination.links.map((link, index) => (
                <li key={index} className={`page-item ${link.active ? 'active' : ''}`}>
                  <a
                    className="page-link"
                    href={link.url || '#'}
                    onClick={(e) => {
                      e.preventDefault();
                      const pageNumber = link.label === 'Next' ? pagination.current_page + 1 : link.label === 'Previous' ? pagination.current_page - 1 : parseInt(link.label);
                      if (pageNumber && pageNumber <= pagination.last_page) fetchProducts(pageNumber);
                    }}
                    style={{ color: 'black', fontSize: '0.9rem' }}
                  >
                    {link.label === '« Previous' ? t('Previous') : link.label === 'Next »' ? t('Next') : link.label}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default ProductContainer2;