import React, { useContext, useState } from "react";
import { CurrencyContext } from '../../context/CurrencyContext'; 
import { useTranslation } from "react-i18next";
import "./currency.css";

const CustomCurrency = () => {
  const { selectedCurrency, changeCurrency, currencyImages } = useContext(CurrencyContext);
  const { t } = useTranslation(); 
  const [isOpen, setIsOpen] = useState(false);

  const handleCurrencyChange = (currency) => {
    changeCurrency(currency);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="custom-dropdown">
      <div 
        className="selected-currency" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedCurrency === "SAR" && (
          <img 
            src={currencyImages["SAR"]} 
            alt="SAR" 
            style={{ width: '24px', height: 'auto', marginRight: '10px' }} 
          />
        )}
        {t(selectedCurrency)}
      </div>
      {isOpen && (
        <ul className="currency-options">
          <li onClick={() => handleCurrencyChange("SAR")}>
            <img 
              src={currencyImages["SAR"]} 
              alt="SAR" 
              style={{ width: '24px', height: 'auto', marginRight: '10px' }} 
            />
            {t('SAR')}
          </li>
          <li onClick={() => handleCurrencyChange("USD")}>
            {t('USD')}
          </li>
          <li onClick={() => handleCurrencyChange("Euro")}>
            {t('Euro')}
          </li>
          <li onClick={() => handleCurrencyChange("Pound")}>
            {t('Pound')}
          </li>
        </ul>
      )}
    </div>
  );
};

export default CustomCurrency;