import React from 'react'
import Layout from '../components/layout/Layout'
import ShopMainCategory from '../components/main/ShopMainCategory'

const ShopCategory = () => {

  return (
    <Layout>
        <ShopMainCategory />
    </Layout> 
  )
}

export default ShopCategory


