import { BrowserRouter as Router, Routes, Route, useSearchParams,useLocation } from "react-router-dom";
import Shop from "./pages/Shop";
import React, {useEffect} from 'react'
import ShopCategory from "./pages/ShopCategory";
import ShopDetails from "./pages/ShopDetails";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import BookAppointment from './pages/BookAppointment';
import Error from "./pages/Error";
import Home from "./pages/Home";
import Shop2 from "./pages/Shop2";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import SignUp from "./pages/SignUp";
import ShippingMethod from "./pages/ShippingMethod";
import Page from "./pages/Page";
import PagePrivacy from './pages/PagePrivacy'
import TrackOrder from "./pages/TrackOrder";
import "./i18n";
import { useTranslation } from 'react-i18next';
import { AuthProvider } from "./components/authentication/AuthContext";
import StoreContextProvider from './context/StoreContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import ThankYou from "./pages/ThankYou";
import ShippingAddress from "./pages/ShippingAddress";
import GoogleCallback from "./pages/GoogleCallback";
import FacebookCallback from "./pages/FacebookCallback";
import AppleCallback from "./pages/AppleCallback";
import TryOn from "./components/shop/TryOn.jsx";
import initializeAnalytics, { trackPageView } from "./analytics.js";
import { CurrencyProvider } from './context/CurrencyContext.jsx';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import useGTM from './useGTM';





function App() {
  useGTM('GTM-P7HHH8MS'); 
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  useEffect(() => {// Replace with your Measurement ID
  }, []);

  const LocationListener = () => {
    const location = useLocation();
    useEffect(() => {
      trackPageView(location.pathname); // Track page views
    }, [location]);

    return null;
  };

  


  
  return (

    <AuthProvider>

    <StoreContextProvider>
    <CurrencyProvider>

    <Router>   
    <LocationListener />
    {/* <TawkMessengerReact
              propertyId="664f4825981b6c5647739d7c"
              widgetId="1huir3kri"
            /> */}
      
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/google" element={<GoogleCallback />}></Route>
        <Route path="/auth/facebook" element={<FacebookCallback />}></Route>
        <Route path="/auth/apple" element={<AppleCallback />}></Route>
        <Route path="/shop" element={<Shop />} /> 
        <Route path="/shop/search" element={<ShopWithParams />} />
        <Route path="/products" element={<ShopWithParams />} />
        {/* <Route path="/products/category/:slug" element={<ShopCategory />} />  */}
          <Route path="/products/category/:slug" element={<ShopCategory />} />
          <Route path="/products/category/:slug/brand/:brandId" element={<ShopCategory />} />
        <Route path="/products/subcategory/:slug" element={<ShopCategory />} /> 
        <Route path="/product/:slug_en" element={<ShopDetails />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/cart" element={<Cart />} /> 
        <Route path="/:slug_en" element={<Page />} />
        <Route path="/account" element={<Account />} />
        <Route path="/shipping-method" element={<ShippingMethod />} />
        <Route path="/shipping-address" element={<ShippingAddress />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/appointment" element={<BookAppointment />} />
        <Route path="/logout" element={<Home />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/track-order" element={<TrackOrder />} />
        <Route path="/privacy_policy" element={<PagePrivacy />} />

        <Route path="/thank-you" element={<ThankYou />} />
             {/* Start Transalte */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetails/:slug_en" element={<BlogDetails />} />
        <Route path="/try-on" element={<TryOn />} />



             {/* End Transalte */}

        <Route path="*" element={<Error />} />

      </Routes>
    </Router>
    </CurrencyProvider>
    </StoreContextProvider>

   </AuthProvider>

  );
}
const ShopWithParams = () => {
  const [searchParams] = useSearchParams();
  const categorySlug = searchParams.get("category_slug"); // Change to category_slug
  const categoryId = searchParams.get("category_id");
  const shapeId = searchParams.get("shape_id");
  const genderId = searchParams.get("gender_id");
  const brandId = searchParams.get("brand_id");

  // Pass all parameters to the Shop component
  return (
    <Shop 
      categorySlug={categorySlug}
      categoryId={categoryId} 
      shapeId={shapeId} 
      genderId={genderId} 
      brandId={brandId} 
    />
  );
};
// const ShopWithParams = () => {

//   const [searchParams] = useSearchParams();
//   const categoryId = searchParams.get("category_id");
//   const shapeId = searchParams.get("shape_id");
//   const genderId = searchParams.get("gender_id");
//   const brandId = searchParams.get("brand_id");
//   if (categoryId) {
//     return <Shop categoryId={categoryId} />;
//   } else if (shapeId) {
//     return <Shop shapeId={shapeId} />;
//   } else if (genderId) {
//     return <Shop genderId={genderId} />;
//   } else if (brandId) {
//     return <Shop brandId={brandId} />;
//   } 
//   else {
//     return <Shop />;
//   }
// };


export default App;
