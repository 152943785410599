import React, { useEffect, useState } from "react";
import { categoryData } from "../../data/Data";
import { Link } from "react-router-dom";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const { t, i18n } = useTranslation(); 



  useEffect(() => {
    axios.get(`${apiUrl}/api/categories`)
     .then(response => {
      setCategories(response.data);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);


  return (
    <section className="fz-5-category-section pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-4">
            <div className="fz-5-section-heading pe-lg-4">
              <h2 className="fz-section-title">{t('Categories')}</h2>
            </div>
          </div>
          <div className="col-lg-8">
            <Swiper
              className="fz-5-category-slider owl-carousel"
              slidesPerView={4}
              autoplay={true}
              loop={true}
              spaceBetween={15}
              navigation={{
                prevEl: ".owl-prev",
                nextEl: ".owl-next",
              }}
              modules={[Autoplay, Navigation]}
            >
              <div className="owl-nav">
                <button type="button" role="presentation" className="owl-prev">
                  <i className="fa-solid fa-angle-left"></i>
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <i className="fa-solid fa-angle-right"></i>
                </button>
              </div>
              {categories.map((category, index) => (
  <SwiperSlide className="fz-5-category-slider-box" key={index}>
    <div className="fz-5-category-slider-img">
    <Link to={`/products/category/${category.slug}`}  style={{ borderRadius: '50%' }}>
    <img src={`${apiUrl}/uploads/${category.image}`} alt="" style={{ borderRadius: '50%' }} />
    </Link>
    </div>
    <div className="fz-5-category-slider-txt">
      <Link to={`/products/category/${category.slug}`} >{i18n.language === 'ar' ? category.name_ar : category.name_en}</Link>
    </div>
  </SwiperSlide>
))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
