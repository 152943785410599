import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../authentication/AuthContext';
import GoogleIcon from '../../../public/assets/images/google-icon.png';
import AppleIcon from '../../../public/assets/images/apple-icon.png';
import FacebookIcon from '../../../public/assets/images/facebook-icon.png';
import ReCAPTCHA from 'react-google-recaptcha';




const apiUrl = import.meta.env.VITE_API_URL;
const SignUpFormSection = () => {
  const { handleRegister, otp, setOtp, otpVerified, handleOtpVerification } = useContext(AuthContext);
  const [showOtpInput, setShowOtpInput] = useState(false);

  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const [loginUrlFacebook, setLoginUrlFacebook] = useState(null);
  const [loginUrlApple, setLoginUrlApple] = useState(null);
  const [capVal, setCapVal] = useState(null);
  


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!userName || !password || !email) {
      toast.error('Please fill out all fields.', { position: 'top-right' });
      return;
    }

    if (!isValidEmail(email)) {
      toast.warning('Please provide a valid email address.', { position: 'top-right' });
      return;
    }

    handleRegister(userName, email, password, phone);
    setShowOtpInput(true); // show OTP input popup
    // toast.success('Registered successfully!', { position: 'top-right' });
    setUserName('');
    setPassword('');
    setEmail('');
    setPhone('');
  };
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    handleOtpVerification(otp);
    navigate('/login');
  };

  useEffect(() => {
    fetch(`${apiUrl}/api/auth/google`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong!');
        })
        .then((data) => setLoginUrlGoogle( data.url ))
        .catch((error) => console.error(error));
}, []);

useEffect(() => {
    fetch(`${apiUrl}/api/auth/facebook`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong!');
        })
        .then((data) => setLoginUrlFacebook( data.url ))
        .catch((error) => console.error(error));
}, []);

useEffect(() => {
  fetch(`${apiUrl}/api/auth/apple`, {
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
  })
      .then((response) => {
          if (response.ok) {
              return response.json();
          }
          throw new Error('Something went wrong!');
      })
      .then((data) => setLoginUrlApple( data.url ))
      .catch((error) => console.error(error));
}, []);



  return (
    <>
      <form action="#" onSubmit={handleFormSubmit}>
        <input
          type="text"
          name="register-name"
          id="register-name"
          placeholder={t('Username')}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <input
          type="email"
          name="register-email"
          id="register-email"
          placeholder={t('Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          name="phone"
          id="register-phone"
          placeholder={t('Phone number')}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <input
          type="password"
          name="register-password"
          id="register-password"
          placeholder={t('Password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
                <ReCAPTCHA 
            sitekey="6Lf1t8QqAAAAAEfBUWakU2TsJu8WU4f3ItzzmSLh"
            onChange={(val) => setCapVal(val)}
            />

        <button disabled={!capVal} type="submit" className="fz-1-banner-btn single-form-btn">
          {t('Register')}
        </button>
      </form>
      {/* <hr />
      <p className="text-center">Or</p>
      <div className="social-icons d-flex justify-content-center m-2">
                <a href={loginUrlGoogle} style={{ marginRight: '10px' }}>
                    <img src={GoogleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                <a href={loginUrlFacebook} style={{ marginRight: '10px' }}>
                    <img src={FacebookIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>

                <a href={loginUrlApple} style={{ marginRight: '10px' }}>
                    <img src={AppleIcon} alt="Google Icon" style={{
                        backgroundColor: '#e3e3e3',
                        borderRadius: '10%',
                        padding: '10px',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                    }} />
                </a>
            </div> */}

            

            {showOtpInput && (
        <div>
          <h2 className="single-form-title">{t('Enter OTP')}</h2>
          <form onSubmit={handleOtpSubmit}>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder={t('Enter OTP')}
            />
            <button className="btn btn-dark" type="submit">{t('Verify OTP')}</button>
          </form>
        </div>
      )}
    </>
  );
};

export default SignUpFormSection;
