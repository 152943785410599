import React from 'react';
import Layout from '../components/layout/Layout';
import PageDetailsMaiPrivacy from '../components/main/PageDetailsMaiPrivacy';


const PagePrivacy = () => {
  return (
    <Layout>
        <PageDetailsMaiPrivacy/>
    </Layout>
  )
}

export default PagePrivacy