import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import PageDetailSectionPrivacy from '../page/PageDetailSectionPrivacy';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const PageDetailsMaiPrivacy = () => {
  const { i18n } = useTranslation();

  // Hardcoded privacy policy text
  const privacyPolicyText = {
    en: `Privacy Policy:

Terms of Use:

By using the Regal Eyes website, you agree to collect and use your data and its impact on this policy, so we ask you to read this policy carefully.

Protection of your information:

Regal Eyes is committed to providing the necessary protection to save the information you provide when you register on the site, and Regal Eyes is also committed not to sell or rent your personal information to any third party without your consent except as stated in this document, so we thank you for your trust and we would like you to use our site with complete safety and comfort.

Third Party:

Regal Eyes employs other companies to perform the functions necessary to provide the services we provide to you through our site, for example: delivery of orders is through shipping service providers, and if you have access to your personal information necessary to perform the functions, not to use it for other purposes.

Information collected:

Regal Eyes collects the information provided by you during our registration on the site, including the following:
1. First, middle and last name.
2. Contact number.
3. Email.
4. Address.

If you wish to delete your account or unsubscribe from the newsletter, you can do so at any time you wish to do so.

Payment data:

Regal Eyes does not provide a direct payment method through its website and provides the following payment methods: (For example)
1. Payment by credit cards.
2. Payment at the branch is available when the customer wishes to receive his order.
3. Payment through Apple Pay.

All the payment methods we provide are safe, God willing, so there is nothing to worry about for its users, otherwise you will be forced to cancel the registration of their bank data within the site to complete the purchase process.

In addition, Regal Eyes uses all advanced security methods of data encryption and protection with the latest available security technologies. Therefore, Regal Eyes does not have the ability to view your banking data when using credit card payment. Therefore, Regal Eyes does not bear any responsibilities towards the user of these payment methods, in the event of an error of any kind.

Methods of using your information:

When collecting your information, Regal Eyes may use it in one of the following areas:
1. Complete the transaction with you.
2. Communicate with you when necessary.
3. Provide you with new offers and services.
4. Improve the services provided to you.

Disclosure of your information:

Regal Eyes is committed not to use your information for any purpose other than the above, and it is also considered after selling or renting your information to any other party except when:
1. Obtaining your permission.
2. The necessity of achieving procedures related to preventing illegal and unlawful activities, such as fraud or posing a threat to the safety and security of any person.
3. Dealing with the terms of use of the Regal Eyes website.
4. Defending Regal Eyes in legal claims.
5. Special circumstances such as procedures for court orders or legal authorities.

Security:

Regal Eyes uses the best security methods available to protect your personal information in our website database, we take all electronic, physical and administrative procedures to protect information, however, we cannot guarantee the protection of your information completely, so by using the Regal Eyes website, we pledge not to bear any responsibility for disclosing your private information due to unauthorized actions by any third party or any errors in transmission.

Registration and access to your data with us:

Regal Eyes does not require your registration and seeks to obtain the contents available on the site from services and products, so registration with us is your choice and the request is when you wish to purchase with us electronically.
To protect your information, when you try to access your account on the site, verify your identity before granting you access to your profile with us. We ask for the username and password you created to create your access to your profile. Update your profile and follow up on your current and previous requests.
You can delete your account at any time if you do not have the required period.
Regal Eyes has the right to update and amend the privacy policy at any time and it is effective to update it on the site and is applicable to all who have registered on the site since its inception until the present time.

By agreeing to this policy, you agree to all of the above.`,
    ar: `سياسة الخصوصية:

شروط الاستخدام:

باستخدام موقع ريجال آيز، فإنك توافق على جمع واستخدام بياناتك وتأثيرها على هذه السياسة، لذا نطلب منك قراءة هذه السياسة بعناية.

حماية معلوماتك:

تلتزم ريجال آيز بتقديم الحماية اللازمة لحفظ المعلومات التي تقدمها عند تسجيلك على الموقع، كما تلتزم ريجال آيز بعدم بيع أو تأجير معلوماتك الشخصية لأي طرف ثالث دون إذنك باستثناء ما هو مذكور في هذا المستند، لذا نشكرك على ثقتك ونود أن تستخدم موقعنا بأمان وراحة تامة.

الطرف الثالث:

تستخدم ريجال آيز شركات أخرى لأداء الوظائف اللازمة لتقديم الخدمات التي نقدمها لك من خلال موقعنا، على سبيل المثال: تسليم الطلبات يتم من خلال مقدمي خدمات الشحن، وإذا كان لديك وصول إلى معلوماتك الشخصية اللازمة لأداء الوظائف، فلا تستخدمها لأغراض أخرى.

المعلومات التي تم جمعها:

تجمع ريجال آيز المعلومات المقدمة من قبلك أثناء تسجيلك على الموقع، بما في ذلك ما يلي:
1. الاسم الأول، الاسم الأوسط، واسم العائلة.
2. رقم الاتصال.
3. البريد الإلكتروني.
4. العنوان.

إذا كنت ترغب في حذف حسابك أو إلغاء الاشتراك من النشرة الإخبارية، يمكنك القيام بذلك في أي وقت ترغب فيه.

بيانات الدفع:

لا تقدم ريجال آيز طريقة دفع مباشرة من خلال موقعها وتوفر طرق الدفع التالية: (على سبيل المثال)
1. الدفع بواسطة بطاقات الائتمان.
2. الدفع في الفرع متاح عندما يرغب العميل في استلام طلبه.
3. الدفع عبر Apple Pay.

جميع طرق الدفع التي نقدمها آمنة، إن شاء الله، لذا لا يوجد ما يدعو للقلق لمستخدميها، خلاف ذلك ستضطر لإلغاء تسجيل بياناتهم المصرفية ضمن الموقع لاستكمال عملية الشراء.

بالإضافة إلى ذلك، تستخدم ريجال آيز جميع طرق الأمان المتقدمة لتشفير وحماية البيانات بأحدث التقنيات المتاحة. لذلك، ليس لدى ريجال آيز القدرة على عرض بياناتك المصرفية عند استخدامك لطريقة دفع بطاقة الائتمان. وبالتالي، لا تتحمل ريجال آيز أي مسؤوليات تجاه مستخدم هذه الطرق في حال حدوث خطأ من أي نوع.

طرق استخدام معلوماتك:

عند جمع معلوماتك، قد تستخدم ريجال آيزها في أحد المجالات التالية:
1. إتمام المعاملة معك.
2. التواصل معك عند الضرورة.
3. تزويدك بعروض وخدمات جديدة.
4. تحسين الخدمات المقدمة لك.

إفشاء معلوماتك:

تلتزم ريجال آيز بعدم استخدام معلوماتك لأي غرض آخر بخلاف ما سبق، كما تعتبر بعد بيع أو تأجير معلوماتك لأي طرف آخر باستثناء عندما:
1. الحصول على إذنك.
2. ضرورة تحقيق الإجراءات المتعلقة بمنع الأنشطة غير القانونية مثل الاحتيال أو تهديد السلامة والأمان لأي شخص.
3. التعامل مع شروط استخدام موقع نظارات ريجال آيز.
4. الدفاع عن نظارات ريجال آيز في دعاوى قانونية.
5. ظروف خاصة مثل الإجراءات المتعلقة بأوامر محكمة أو السلطات القانونية.

الأمان:

تستخدم نظارات ريجال آيز أفضل طرق الأمان المتاحة لحماية معلوماتك الشخصية في قاعدة بيانات موقعنا، نتخذ جميع الإجراءات الإلكترونية والفيزيائية والإدارية لحماية المعلومات، ومع ذلك، لا يمكننا ضمان حماية معلوماتك بشكل كامل، لذا باستخدامك لموقع نظارات ريجال آيز، نعدك بعدم تحمل أي مسؤولية عن إفشاء معلوماتك الخاصة بسبب أفعال غير مصرح بها من أي طرف ثالث أو أي أخطاء في النقل.

التسجيل والوصول إلى بياناتك معنا:

لا تتطلب نظارات ريجال آيز تسجيلك وتسعى للحصول على المحتويات المتاحة على الموقع من خدمات ومنتجات، لذا فإن التسجيل معنا هو خيارك والطلب هو عندما ترغب في الشراء معنا إلكترونيًا.
لحماية معلوماتك، عند محاولة الوصول إلى حسابك على الموقع، تحقق من هويتك قبل منحك الوصول إلى ملفك الشخصي معنا. نطلب اسم المستخدم وكلمة المرور التي أنشأتها لإنشاء وصولك إلى ملفك الشخصي. تحديث ملفك الشخصي ومتابعة طلباتك الحالية والسابقة.
يمكنك حذف حسابك في أي وقت إذا لم يكن لديك الفترة المطلوبة.
تحتفظ ريجال آيز بحق تحديث وتعديل سياسة الخصوصية في أي وقت وتكون سارية لتحديثها على الموقع وتطبق على جميع من سجلوا في الموقع منذ بدايته حتى الوقت الحاضر.

بموافقتك على هذه السياسة، توافق على كل ما سبق.`
  };

  // SEO metadata
  const metaTitle = i18n.language === 'ar' ? "ريجل ايز | سياسة الخصوصية" : "Regal Eyes | Privacy Policy";
  const metaDescription = i18n.language === 'ar' ? "تفاصيل سياسة الخصوصية لموقع ريجال ايز." : "Details of the privacy policy for the Regal Eyes website.";
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={currentKeywords} />
      </Helmet>
      <BreadcrumbSection title={i18n.language === 'ar' ? "سياسة الخصوصية" : "Privacy Policy"} />
      <PageDetailSectionPrivacy privacyText={privacyPolicyText[i18n.language]} />
    </>
  );
}

export default PageDetailsMaiPrivacy;