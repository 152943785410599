import React, { useState, useEffect } from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import PageDetailSection from '../page/PageDetailSection';
import { useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const apiUrl = import.meta.env.VITE_API_URL;
const PageDetailsMain = () => {
  const { i18n } = useTranslation();
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug_en } = useParams();
  // SEO Start
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const titles = {
    en: "Regalesyes | ",
    ar: "ريجل ايز | "
  };  

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
  const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
// SEO End



  useEffect(() => {
    fetchPageDetails(slug_en);
  }, [slug_en]);

  // const fetchPageDetails = (slug_en) => {
  //   fetch(`${apiUrl}/api/page/${slug_en}`)
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw new Error('Failed to fetch page details');
  //       }
  //     })
  //     .then(data => {
  //       setPage(data);
  //       setMetaTitle(i18n.language === 'ar' ? data.meta_title_ar : data.meta_title_en);
  //       setMetaDescription(i18n.language === 'ar' ? data.meta_description_ar : data.meta_description_en);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       setError(error.message);
  //       setLoading(false);
  //     });

  // };
  const fetchPageDetails = async (slug_en) => {
    try {
      const response = await fetch(`${apiUrl}/api/page/${slug_en}`);
      if (!response.ok) {
        throw new Error('Failed to fetch page details');
      }
      const data = await response.json();
      
      // Check if the page ID is 1 and handle accordingly
      if (data.id === 1) {
        console.warn('Page with ID 1 is not allowed to be fetched.');
        setError('This page is not available.');
        setLoading(false);
        return; // Exit the function early
      }
  
      console.log('Fetched page data:', data); // Log the fetched data
      setPage(data);
      setMetaTitle(i18n.language === 'ar' ? data.meta_title_ar : data.meta_title_en);
      setMetaDescription(i18n.language === 'ar' ? data.meta_description_ar : data.meta_description_en);
    } catch (error) {
      console.error('Error fetching page details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <>
              <Helmet>
  <title>{currentTitles} {metaTitle}</title>
  <meta name="description" content={metaDescription} />
  <meta name="keywords" content={currentKeywords} />
  </Helmet>
        <BreadcrumbSection title={i18n.language === 'ar' ? page.title_ar : page.title_en} current= {i18n.language === 'ar' ? page.title_ar : page.title_en} />
        <PageDetailSection/>
    </>
  )
}

export default PageDetailsMain