import React, {useState} from 'react'
import SearchFilter from './SearchFilter'
import ProductCategoryList2 from './ProductCategoryList2';
import GenderSelectFilter from './GenderSelectFilter';
import FrameShapeFilter from './FrameShapeFilter';
import FrameBrandFilter from './FrameBrandFilter';
import ProductPriceFilter from './ProductPriceFilter';
import ProductViewFilter from './ProductViewFilter';
import ProductContainer from './ProductContainer';
import ProductPagination from './ProductPagination';
import { useSearchParams } from 'react-router-dom';




const ShopAreaSection = ({ sidebarRef, active}) => {
    const [searchParams] = useSearchParams();
    const shapeId = searchParams.get("shape_id");
    const genderId = searchParams.get("gender_id");
    const brandId = searchParams.get("brand_id");
    const [filteredProducts, setFilteredProducts] = useState([]); // State to hold filtered products


  return (
    <div className="shop-area">
        <div className="container">
            <div className="row gy-5 justify-content-center">
                {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-9 col-xxs-12 order-1 order-lg-0">
                    
             <div className={`fz-sidebar fz-sidebar-2 ${active ? "active" : ""}`} ref={sidebarRef}> */}
                             <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-9 col-xxs-12 order-1 order-lg-0">
                        <div className={`fz-sidebar fz-sidebar-2 ${active ? "active" : ""}`} ref={sidebarRef}>

                         <SearchFilter/>    
                         <FrameBrandFilter />   
                         <ProductCategoryList2 />
                        <GenderSelectFilter />  
                        <FrameShapeFilter  />   
                        <ProductPriceFilter setSearchProducts={setFilteredProducts}  />  

                    </div>
                </div>

                <div className="col-xl-9 col-lg-8 order-2 order-lg-1">
                {/* <div className="col-12 order-2"> */}

                    <ProductContainer 
                    shapeId={shapeId} 
                    genderId={genderId}
                     brandId={brandId} 
                     filteredProducts={filteredProducts}
                     setFilteredProducts={setFilteredProducts} />

                </div>
            </div>
        </div>
    </div>
  )
}

export default ShopAreaSection

